const axios = require('axios');

function checkUserRecipe(search) {
  if (!search) return;

  const searchInput = $('input[name="name"]');

  searchInput.blur(function () {
    axios.get(`/api/userRecipeCheck?q=${this.value}`).then((res) => {
      if (res.data.length) {
        $('.search_result').show().html('Recepta o takiej nazwie już istnieje');
      }
    });
  });

  searchInput.focus(function () {
    $('.search_result').hide();
  });
}

export default checkUserRecipe;
