import '../sass/style.scss';
// jQuery
import $ from 'jquery';
// import jQuery from 'jquery';

// selectize
import 'selectize';
// ajax recipe check
import swal from 'sweetalert';
import checkRecipe from './modules/checkRecipe';
import checkUserRecipe from './modules/checkUserRecipe.js';

// import and start whole foundation
import 'foundation-sites';

window.$ = $;
window.swal = swal;

checkRecipe($('.search'));
checkUserRecipe($('.search'));

$(document).foundation();

// add another ingredient input
$('#addIngredient').click(function (e) {
  e.preventDefault();
  const newRow = $('#add-ingredient-row').html();
  const added = $('.ingredients').append(newRow);
  $(added).find('select').last().attr('name', 'ingredient_name').selectize();

  $(this).parents().eq(2).focus();
  $(':input').each(function (i) {
    $(this).attr('tabindex', i + 1);
  });

  $('.delete-button').click(function (event) {
    event.preventDefault();
    $(this)
      .parents()
      .eq(1)
      .fadeOut(300, function () {
        $(this).remove();
      });
  });
});

$('#addIngredient').on('click', '.ingredients', function () {
  console.log(this);
});

// remove this ingredient input
$('.delete-button').click(function (e) {
  e.preventDefault();
  $(this)
    .parents()
    .eq(1)
    .fadeOut(300, function () {
      $(this).remove();
    });
});

// fix ingredient price on save and update
$('#postIngredient').submit(function (e) {
  e.preventDefault();

  $('.decimal-change').val(function (i, value) {
    /* Return the new value here. "value" is the old value of the input: */
    return value.replace(/,/g, '.');
  });

  $('#postIngredient')[0].submit();
});

// check ingredient amounts on recipe save end edit
$('#editRecipe').submit(function (e) {
  e.preventDefault();

  $('.decimal-change').val(function (i, value) {
    /* Return the new value here. "value" is the old value of the input: */
    return value.replace(/,/g, '.');
  });
  let totalPercentage = 0;
  $('#editRecipe input[name=ingredient_amount]').each(function () {
    totalPercentage = parseFloat($(this).val()) + totalPercentage;
  });
  totalPercentage = Math.fround(totalPercentage.toFixed(5));
  if (totalPercentage !== 100) {
    swal({
      title: 'Błąd!',
      text: `Suma wartości składników wynosi: ${totalPercentage.toFixed(
        5
      )}%. Popraw receptę`,
      icon: 'error',
    });
  } else {
    $('#editRecipe')[0].submit();
  }
});

// calculate needed recipe amounts
$('#calculate').click(function () {
  const calBefValue = $('#calValue').val();
  const calValue = calBefValue.replace(/,/g, '.');
  const regex = /^-?\d*\.?\d*$/;
  const isTrue = regex.test(calValue);
  if (!isTrue) {
    swal('Podaj prawidłową liczbę');
    return;
  }
  const dens = $('#density').text();
  const density = parseFloat(dens);
  const rows = $('#tabela > tbody > tr').length;
  const table = $('#tabela tbody');

  table.find('tr').each(function (i) {
    const $tds = $(this).find('td');
    const value = $tds.eq(1).text();
    const empty = $tds.eq(2);

    const result = (((value * calValue * density) / 100) * 1000).toFixed(3);
    empty.html(`${result} g`);
  });

  // if user (not admin) page generated - recalculate price
  // var percentage = $('#calculatePriceValue').val();
  // if (percentage) {
  //     calculatePrice();
  // }
});
// calculate recipe price
function calculatePrice() {
  // check if proper number
  const calBefValue = $('#calculatePriceValue').val();
  const regex = /^-?\d*\.?\d*$/;
  const isTrue = regex.test(calBefValue);
  if (!isTrue) {
    swal('Podaj prawidłową liczbę (same cyfry, bez %)');
    return;
  }

  // change comma to dot and strip %
  const ChangeToDot = calBefValue.replace(/,/g, '.');
  const calValue = ChangeToDot.replace(/%/g, '');

  // get density
  const dens = $('#density').text();
  const density = parseFloat(dens);

  // check if potion already calculated
  const amount = $('#calValue').val();

  let pricesSet = true;
  const table = $('#tabela tbody');
  let total = 0;
  table.find('tr').each(function (i) {
    const $tds = $(this).find('td');
    if ($tds.eq(3).text() === 0) {
      pricesSet = false;
      swal('Błąd', 'Nie masz ustawionych cen wszystkich składników.', 'error');
      return;
    }
    const percentage = $tds.eq(1).text();
    const price = $tds.eq(3).text() / 100;
    const subtotal = percentage * price;
    total += subtotal;
  });
  const final = (total + total * (calValue / 100)).toFixed(2);
  const result = $('#priceCalculated');
  const finalString = `${final} zł`;
  if (pricesSet) {
    result.html(finalString);
  } else {
    result.html('0 zł');
  }
}
$('#calculatePrice').click(calculatePrice);

// keyboard movement helper
$('#calValue').keyup(function (event) {
  if (event.keyCode === 13) {
    $('#calculate').click();
  }
});

$('#calculatePriceValue').keyup(function (event) {
  if (event.keyCode === 13) {
    $('#calculatePrice').click();
  }
});

// confirm deletions
$('.deletion').click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  swal({
    title: 'Jesteś pewien?',
    text: 'Czy na pewno usunąć?',
    dangerMode: true,
    icon: 'warning',
    buttons: ['Anuluj', 'Usuń'],
  }).then((willDelete) => {
    if (willDelete) {
      window.location = $(this).attr('href');
    }
  });
});

// fast recipe search (filter)
$('#searchInput')
  .keyup(function () {
    // split the current value of searchInput
    const data = this.value.toUpperCase().split(' ');
    // create a jquery object of the rows
    const jo = $('#fbody').find('tr');
    if (this.value === '') {
      jo.show();
      return;
    }
    // hide all the rows
    jo.hide();

    // Recusively filter the jquery object to get results.
    jo.filter(function (i, v) {
      const $t = $(this);
      for (let d = 0; d < data.length; ++d) {
        if ($t.text().toUpperCase().indexOf(data[d]) > -1) {
          return true;
        }
      }
      return false;
    })
      // show the rows that match.
      .show();
  })
  .focus(function () {
    this.value = '';
    $(this).css({
      color: 'black',
    });
    $(this).unbind('focus');
  })
  .css({
    color: '#C0C0C0',
  });

// tabindex setup for whole page
$(':input').each(function (i) {
  $(this).attr('tabindex', i + 1);
});
$('.main-table-row').each(function (i) {
  $(this).attr('tabindex', i + 1);
});

// keyboard movement helper
$('.main-table-row').keydown(function (e) {
  if (e.which === 40) {
    $(this).next('.main-table-row').focus();
  }
});

// keyboard movement helper
$('.main-table-row').keydown(function (e) {
  if (e.which === 40) {
    const $this = $(this);

    const index = $this.closest('tr').index();

    $this
      .closest('tr')
      .next()
      .find('tr')
      .eq(index)
      .find('.main-table-row')
      .focus();
    e.preventDefault();
  }

  if (e.which === 13) {
    window.location = $(this).data('url');
  }
});

// whole table row is a link
$('.main-table-row').click(function (e) {
  window.location = $(this).data('url');
});

// whole menu li is a link including padding
$('.menu-link').click(function () {
  window.location = $(this).find('a').attr('href');
  return false;
});

$("[name='ingredient_name']").selectize();

// document.addEventListener('DOMContentLoaded', function () {
//   const searchInput = document.getElementsByClassName('gridjs-search-input');
//   if (searchInput.length) console.log(searchInput[0]);
// });
