const axios = require('axios');

function checkRecipe(search) {
  if (!search) return;

  const searchInput = $('input[name="name"');

  searchInput.blur(function () {
    axios.get(`/api/recipeCheck?q=${this.value}`).then((res) => {
      if (res.data.length) {
        $('.search_result').show().html('Recepta o takiej nazwie już istnieje');
      }
    });
  });

  searchInput.focus(function () {
    $('.search_result').hide();
  });
}

export default checkRecipe;
